import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'

const ShareButtons = ({title, url, twitterHandle, tags}) => {

    return(
        <div>
          <FacebookShareButton url={url} className="mr-1">
                <FacebookIcon size={40} round={true}/>
         </FacebookShareButton>

          <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags} className="mr-1">
                <TwitterIcon size={40} round={true} />
          </TwitterShareButton>


          <RedditShareButton url={url} title={title}  className="mr-1">
            <RedditIcon  size={40} round={true} />
          </RedditShareButton>

        </div>
      )

}
export default ShareButtons
