import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import parseBlogPost from "../parsers/blog-post"
import BlogPost from "../components/BlogPost"
import  { dateFormatLong } from '../utils/date'
import Img from "gatsby-image"
import SEO from "../components/seo"
import ShareButtons from "../components/ShareButtons"

const BlogPostTemplate = ({data}) => {
  const blogPost = parseBlogPost(data.markdownRemark)

  return (
    <Layout>
      <SEO
        title={blogPost.title}
        description={blogPost.excerpt}
        type="article"
        image={data.site.siteMetadata.url + blogPost.image.src}
      />
        <section className="hero">
          <div className="container">
            <div className="hero-body">
              <h1 className="title is-size-1  is-size-4-mobile">
              {blogPost.title}
              </h1>
            </div>
          </div>
        </section>
        <section>
          {blogPost.image && <Img fluid={blogPost.image} />}
        </section>
        <section className="columns is-size-6">
          <div className="column is-three-fifths has-text-justified" dangerouslySetInnerHTML={{__html: blogPost.body}}></div>
          <div className="column ">
            <div className="colums">
              <div className="column is-offset-3">
                <p className="subtitle is-6">Posté le {dateFormatLong(blogPost.publishedAt)}</p>
                <h3 className="subtitle is-5">Partager</h3>
                <ShareButtons title={blogPost.title} url={data.site.siteMetadata.url + blogPost.link} twitterHandle="yrocq " />
              </div>
            </div>

</div>
        </section>
    </Layout>
  )
}


export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile(
      filter: {
        sourceInstanceName:{
          eq:"blog"
        }
        internal: {
          mediaType: {
            regex:  "/image\/.*/"
          }
        }
      }
    ) {
      edges {
        node {
          relativePath
          publicURL
          sourceInstanceName
          name
          ext
        }
      }
    }
    }
`
